import(/* webpackMode: "eager" */ "/home/front/auto-desk/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_react-dom@18.3.1_react@18.3.1_sass@1.77.8/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/front/auto-desk/src/components/Main/layouts/BigContactForm/Btn.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/front/auto-desk/src/components/Main/layouts/BigContactForm/Form.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/front/auto-desk/src/components/Main/layouts/Header/ActiveLink.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/front/auto-desk/src/components/Main/shared/HotCars.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/front/auto-desk/src/components/Main/shared/Managers.js");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/imgs/form_car.jpg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/svg/arrow.svg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/svg/bigForm/case.svg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/svg/bigForm/hexagon.svg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/svg/fireCar.svg");
